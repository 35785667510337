<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <form @submit.prevent="auth(model)">
                  <h1>{{ $t('Авторизация') }}</h1>
                  <p class="text-muted">{{ $t('Войдите в свой аккаунт') }}</p>
                  <CInput
                      type="email"
                      v-model.lazy="model.email"
                      required
                      placeholder="E-mail"
                      autocomplete="email"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"/>
                    </template>
                  </CInput>
                  <CInput
                      :placeholder="$t('Пароль')"
                      type="password"
                      autocomplete="password"
                      required
                      v-model.lazy="model.password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" color="primary" class="px-4">{{ $t('Войти') }}</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <router-link to="/password"><CButton color="link" class="px-0">{{ $t('Забыли пароль?') }}</CButton></router-link>
                      <CButton color="link" class="d-lg-none">Зарегистрироваться</CButton>
                    </CCol>
                  </CRow>
                </form>
              </CCardBody>
              <CCardFooter style="display:none;">
                <CRow>
                  <CCol col="4">
                    <CButton block color="facebook" @click="logInWithFacebook">
                      Facebook
                    </CButton>
                  </CCol>
                  <CCol col="4">
                    <CButton block color="vk">
                      VK
                    </CButton>
                  </CCol>
                  <CCol col="4">
                    <CButton block color="youtube">
                      Google
                    </CButton>
                  </CCol>
                </CRow>
              </CCardFooter>
            </CCard>
            <CCard
                color="primary"
                text-color="white"
                class="text-center py-5 d-md-down-none"
                body-wrapper
            >
              <CCardBody>
                <h2 hidden>{{ $t('Регистрация') }}</h2>
                <p hidden>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua.</p>
                <router-link to="/register">
                  <CButton
                    color="light"
                    variant="outline"
                    size="lg"
                  >
                    {{ $t('Зарегистрироваться') }}
                  </CButton>
                </router-link>
                <notifications group="auth" />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import axios from '@/modules/axios'

export default {
  name: 'Login',
  data() {
    return {
      model: {
        email: '',
        password: ''
      }
    }
  },
  created() {
    if(localStorage.getItem('jwt')){
      this.$router.push('/calendars/list')
    }
    // window.fbAsyncInit = function() {
    //   FB.init({
    //     appId      : '197698635365853',
    //     cookie     : true,
    //     xfbml      : true,
    //     version    : 'v10.0'
    //   });
    //
    //   FB.AppEvents.logPageView();
    //
    // };
    //
    // (function(d, s, id){
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {return;}
    //   js = d.createElement(s); js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
    //
    // FB.getLoginStatus(function(response) {
    //   statusChangeCallback(response);
    // });
  },
  methods: {
    auth({email, password}) {
      const data = {
        password: password,
        email: email
      }
      axios.post('/auth/login', data, {
        headers: {'content-type': 'application/json'}
      }).then((res) => {
        const user = res.data.data;
        this.$store.dispatch('setUser', user);
        localStorage.setItem('jwt', user.jwt)
        this.$router.push('/calendar/list')
      }).catch((error) => {
        this.$notify({group: 'auth', text: error.response?.data.message, type: 'error'})
      })
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      window.FB.login(function(response) {
        if (response.authResponse) {
          alert("You are logged in &amp; cookie set!");
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
        } else {
          alert("User cancelled login or did not fully authorize.");
        }
      });
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "197698635365853", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v10.0"
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
}
</script>
